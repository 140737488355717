// @ts-check
import React from "react"
import styled from "@emotion/styled"

import colors from "../../styles/colors"
import Typography, { TypographyMarkdown } from "../typography"
import { HoverButton } from "../button"
import CardSeparator from "../cardSeparator"
import { BUTTON_ACTION_SIGNUP } from "../../content/pricingContent"
import DemoContext from "../../contexts/demo"
import { MobileMediaQuery } from "../../styles/constants"

/**
 * @prop {string} props.cardColor
 */
const Wrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  margin: 1rem;

  width: 19rem;

  border-radius: 24px;
  border-top: solid 24px
    ${(/** @type {{ variant: string }}*/ p) => colors[p.variant]};
  flex: 1 0 auto;
`

const Title = styled(Typography)`
  margin: 1.3rem 0;
  text-align: center;
`
const Subtitle = styled(Typography)`
  text-align: center;
  height: 3.125rem;
  padding: 0 2.1rem;
  margin-bottom: 1rem;
`

const CTAButton = styled(HoverButton)`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-transform: none;
  border-radius: 100px;
  height: 90px;
`

const Separator = styled(CardSeparator)`
  width: 100%;
  ${MobileMediaQuery} {
    margin: 0px;
  }
`

const ProductPitch = styled.ul`
  font-size: 15px;
  line-height: 25px;
  margin-left: 1.3rem;
  margin-top: 0;
  margin-bottom: 2rem;
  color: ${colors.darkgrey};
`
/**
 * @param {Object} props
 * @param {string} props.variant
 * @param {string} props.title
 * @param {string} [props.subtitle]
 * @param {string} props.price
 * @param {string} props.buttonText
 * @param {string} [props.buttonCaption]
 * @param {string} props.buttonAction
 * @param {string} [props.productIncludes]
 * @param {string[]} props.productPitch
 */
const CardPricing = ({
  variant,
  title,
  subtitle,
  price,
  buttonText,
  buttonCaption,
  buttonAction,
  productIncludes,
  productPitch,
}) => {
  const { open } = React.useContext(DemoContext)

  const ctaButton =
    buttonAction === BUTTON_ACTION_SIGNUP ? (
      <CTAButton
        as="a"
        center
        href="/en/accelerator-program/"
        variant={variant}
      >
        <Typography variant="bodyLarge" style={{ textAlign: "center" }}>
          {buttonText}
        </Typography>
        {buttonCaption && (
          <Typography variant="small" style={{ marginTop: "0.2rem" }}>
            {buttonCaption}
          </Typography>
        )}
      </CTAButton>
    ) : (
      <CTAButton
        center
        variant={variant}
        onClick={e =>
          open(e, {
            category: "open_get_a_demo",
            label: `pricing open get a demo ${title}`,
            siteSection: "pricing_card",
            from: `pricing-card-${title}`,
          })
        }
      >
        <Typography variant="bodyLarge">{buttonText}</Typography>
        {buttonCaption && (
          <Typography variant="small" style={{ marginTop: "0.2rem" }}>
            {buttonCaption}
          </Typography>
        )}
      </CTAButton>
    )

  return (
    <Wrapper variant={variant}>
      <Title variant="h5" as="h2" style={{ color: colors.darkgrey }}>
        {title}
      </Title>
      <Subtitle
        variant="caption"
        style={{ color: colors.darkgrey, fontWeight: 400 }}
      >
        {subtitle}
      </Subtitle>
      <Separator variant="lightgrey" style={{ marginBottom: "2rem" }} />
      {/* <Price>
        <PriceTypo
          variant={!isEnterprise ? "h1" : "h5"}
          isEnterprise={isEnterprise}
          style={{ color: colors[variant] }}
        >
          {price}
        </PriceTypo>
        {!isEnterprise && (
          <Typography
            variant="caption"
            style={{ color: colors.darkgrey, marginTop: "0.2rem" }}
          >
            per month, paid annually
          </Typography>
        )}
      </Price> */}
      {ctaButton}
      <Separator
        variant="lightgrey"
        style={{ marginBottom: "2rem", marginTop: "2rem" }}
      />
      {productIncludes && (
        <TypographyMarkdown
          style={{
            fontSize: "15px",
            lineHeight: "25px",
            marginLeft: "2.45rem",
            color: colors.darkgrey,
            marginBottom: "5px",
          }}
        >
          {productIncludes}
        </TypographyMarkdown>
      )}
      <ProductPitch>
        {productPitch.map(pitch => (
          <li key={pitch}>{pitch}</li>
        ))}
      </ProductPitch>
    </Wrapper>
  )
}

export default CardPricing
