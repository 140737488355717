import React from "react"
import styled from "@emotion/styled"

import Card from "../card"
import Typography, {
  typographyComponents,
  typographyStyles,
} from "../typography"
import colors from "../../styles/colors"
import { DesktopMediaQuery, MobileMediaQuery } from "../../styles/constants"
import { SendingButton } from "../button"
import Input from "../input"
import { isPersonalEmail } from "../../../shared/utils"
import { FUNCTIONS_ENDPOINT } from "../../config"
import Axios from "axios"
import { trackError } from "../../utils/segment"
import { trackEvent, identify } from "../../utils/trackEvent"

const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const InputForm = styled.form`
  display: flex;
  flex-direction: column;

  ${MobileMediaQuery} {
    height: auto;
    width: 100%;
    margin-top: 2rem;
  }
`

const EmailInput = styled(Input)`
  margin-bottom: 0;
  border-radius: 4px 0 0 4px;
  max-width: 325px;
  font-size: 16px;
  background-color: ${colors.lightpurple};
  color: ${colors.light};

  &::placeholder {
    color: ${colors.light};
  }

  ${DesktopMediaQuery}  {
    width: 330px;
  }
`

const ContactButton = styled(SendingButton)`
  ${typographyStyles.bodyLarge}
  outline: none;
  height: 90px;
  max-width: 330px;
  border-radius: 100px;
  background: transparent;
  border: 2px solid ${colors.primary};
  color: ${p => (p.isSending ? "transperent" : colors.primary)};
  margin-top: 3.125rem;
  text-transform: none;

  &:hover {
    ${DesktopMediaQuery} {
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }

  &:active {
    background-color: ${colors.white};
  }
`

const Title = styled(Typography)`
  margin-right: 2rem;
  ${DesktopMediaQuery} {
    margin-right: 1rem;
  }
`

const ErrorText = styled(typographyComponents.small)`
  margin-top: 1rem;
  color: ${colors.light};
`

const CardWhichPlan = ({ ...props }) => {
  const [email, setEmail] = React.useState("")
  const [isSending, setIsSending] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const isPersonal = isPersonalEmail(email)

  const onSubmit = React.useCallback(
    async e => {
      e.preventDefault()
      setIsSending(true)
      trackEvent({
        category: "generate_lead",
        label: "pricing contact me submitted",
        siteSection: "pricing_contact_me",
      })
      identify(email)
      try {
        const response = await Axios.post(
          `${FUNCTIONS_ENDPOINT}/request-demo`,
          {
            email,
            from: "pricing-contact-me",
          }
        )
        if (response.data.status === "success") {
          setIsSuccess(true)
          console.log("Success")
        } else {
          throw new Error(
            `Pricing contact request failed: ${JSON.stringify(
              response.data,
              null,
              2
            )}`
          )
        }
      } catch (e) {
        trackError(e)
        setIsSuccess(false)
      } finally {
        setIsSending(false)
      }
    },
    [email]
  )

  return (
    <Card variant="dark" noMinHeight style={{ width: "100%" }} {...props}>
      <Columns>
        <Title variant="h3">
          <span style={{ color: colors.primary }}>
            Which plan is right for you?
          </span>
          <br />
          We’re happy to help
        </Title>
        {isSuccess ? (
          <span style={{ marginTop: "2rem" }}>
            <Typography variant="h4">
              We will get back to you shortly
            </Typography>
          </span>
        ) : (
          <InputForm onSubmit={onSubmit}>
            <EmailInput
              type="email"
              required
              placeholder="Your work email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            {isPersonal && (
              <ErrorText>
                <span aria-hidden>⚠️</span> It looks like you have entered your
                personal e-mail address. Right now our product is intended for
                business use only. To request a demo, please use your business
                email address (no Gmail, Hotmail etc).
              </ErrorText>
            )}
            <ContactButton
              type="submit"
              disabled={isSending || isPersonal}
              isSending={isSending}
            >
              Contact me
            </ContactButton>
          </InputForm>
        )}
      </Columns>
    </Card>
  )
}

export default CardWhichPlan
