export const STANDARD = "Standard"
export const PRIME = "Accelerator Program"
export const ENTERPRISE = "Enterprise"

export const BUTTON_ACTION_SIGNUP = "BUTTON_ACTION_SIGNUP"
export const BUTTON_ACTION_DEMO = "BUTTON_ACTION_DEMO"

export const pricingContent = [
  {
    variant: "primary",
    title: STANDARD,
    subtitle:
      "For small teams looking to learn about seamless hardware product support",
    price: "€150",
    buttonText: "Get a custom demo",
    buttonCaption: null, //"No credit card, no strings",
    buttonAction: BUTTON_ACTION_DEMO,
    productIncludes: null,
    productPitch: [
      "Product support assistant",
      "Basic flow templates",
      "Website widget",
      "Standard CRM integration",
    ],
  },
  {
    variant: "lightpurple",
    title: PRIME,
    subtitle: "For growing companies that are ready to grow to the next level",
    price: "€800",
    buttonText: "Learn more & apply",
    buttonAction: BUTTON_ACTION_SIGNUP,
    buttonCaption: null,
    productIncludes: `Everything in ${STANDARD}, plus:`,
    productPitch: [
      "White-glove and dedicated Onboarding Manager",
      "World-class hardware startup advice",
      "All out-of-the-box integrations and features",
    ],
  },
  {
    variant: "dark",
    title: ENTERPRISE,
    subtitle: "For large organizations with custom support needs",
    price: "Custom pricing",
    buttonText: "Chat with us",
    buttonAction: BUTTON_ACTION_DEMO,
    buttonCaption: null,
    productIncludes: null,
    productPitch: [
      "25 seats included",
      "Flow building & upkeep",
      "Dedicated Onboarding Manager",
      "Custom branding",
      "Custom integrations",
    ],
  },
]

export const pricingPlans = {
  levels: [STANDARD, PRIME, ENTERPRISE],
  ctaButtons: {
    desktop: [
      {
        buttonAction: BUTTON_ACTION_DEMO,
        text: `Get ${STANDARD}`,
      },
      {
        buttonAction: BUTTON_ACTION_SIGNUP,
        text: `Get ${PRIME}`,
      },
      {
        buttonAction: BUTTON_ACTION_DEMO,
        text: `Get ${ENTERPRISE}`,
      },
    ],
    mobile: [
      {
        buttonAction: BUTTON_ACTION_SIGNUP,
        text: `Try ${STANDARD}`,
      },
      {
        buttonAction: BUTTON_ACTION_DEMO,
        text: `Get ${PRIME}`,
      },
      {
        buttonAction: BUTTON_ACTION_DEMO,
        text: `Get ${ENTERPRISE}`,
      },
    ],
  },
  categories: [
    {
      title: "Usage",
      type: "STRING",
      features: {
        // seatsIncluded: [3, 10, 25],
        // additionalSeats: [
        //   <span>
        //     €50/month
        //     <br />
        //     /seat
        //   </span>,
        //   <span>
        //     €80/month
        //     <br />
        //     /seat
        //   </span>,
        //   <span>
        //     €80/month
        //     <br />
        //     /seat
        //   </span>,
        // ],
        maximumSeats: [10, "Unlimited", "Unlimited"],
        // conversationsIncluded: ["1,000/month", "1,000/month", "Quote"],
        // additionalConversations: [
        //   <span>
        //     €300/month
        //     <br />
        //     /1k extra
        //   </span>,
        //   <span>
        //     €300/month
        //     <br />
        //     /1k extra
        //   </span>,
        //   "Quote",
        // ],
        // maximumConversations: ["20,000/month", "20,000/month", "Unlimited"],
      },
    },
    {
      title: "AI-guided self-service",
      type: "CHECKBOX",
      features: {
        unlimitedFlows: [true, true, true],
        naturalLanguageUnderstanding: [true, true, true],
        flowTemplates: [true, true, true],
        dynamicForms: [false, true, true],
        translation: [false, true, true],
        scriptsRoutines: [false, true, true],
        contentGeneration: [false, true, true],
        flowBuildingUpkeep: [false, false, true],
      },
    },
    {
      title: "Remote human service",
      type: "CHECKBOX",
      features: {
        photoDrivenLiveChat: [false, true, true],
        interactiveVideoSupport: [false, true, true],
        cannedResponses: [false, true, true],
        customAgentHandover: [false, true, true],
      },
    },
    {
      title: "Channels",
      type: "CHECKBOX",
      features: {
        websiteWidget: [true, true, true],
        directVideoChatLink: [false, true, true],
        app: [false, true, true],
        eCommerce: [false, true, true],
        copilot: [false, true, true],
        socialMedia: [false, true, true],
        qrCode: [false, true, true],
      },
    },
    {
      title: "Application management",
      type: "CHECKBOX",
      features: {
        customizableWidget: [true, true, true],
        teamManagement: [true, true, true],
        customCRMIntegration: [false, true, true],
        dataExports: [false, true, true],
        insightDashboard: [false, true, true],
      },
    },
    {
      title: "Support & expertise",
      type: "CHECKBOX",
      features: {
        helpCenter: [true, true, true],
        emailSupport: [true, true, true],
        imports: [false, true, true],
        apiIntegrationSupport: [false, true, true],
        premiumSupport: [false, true, true],
        dedicatedSolutionManager: [false, true, true],
        solutionManagementTeam: [false, false, true],
      },
    },
  ],
}

export const pricingPlansTranslations = {
  unlimitedFlows: {
    name: "Unlimited product assistant flows",
    helpText:
      "Build for unlimited products and use-cases flows (eCommerce, warranty-handling, troubleshooting, loyalty etc.)",
  },
  additionalSeats: {
    name: "Additional seats",
    helpText: null,
  },
  maximumSeats: {
    name: "Maximum seats",
    helpText: null,
  },
  conversationsIncluded: {
    name: "Conversations included",
    helpText: null,
  },
  additionalConversations: {
    name: "Additional conversations",
    helpText: null,
  },
  maximumConversations: {
    name: "Maximum conversations",
    helpText: null,
  },
  productAssistant: {
    name: "Product assistant",
    helpText: null,
  },
  naturalLanguageUnderstanding: {
    name: "Natural language understanding (NLU)",
    helpText:
      "People describe things differently. Our NLU is pre-trained for hardware and improves with every search.",
  },
  flowTemplates: {
    name: "Robust flow templates",
    helpText:
      "We work with everything from electric scooters and headphones to toothbrushing robots and smart gardens - and have templates for each segments' needs.",
  },
  dynamicForms: {
    name: "Dynamic forms",
    helpText:
      "Share and collect helpful information. Customers should never repeat themselves.",
  },
  guides: {
    name: "Guides",
    helpText: null,
  },
  triage: {
    name: "Triage",
    helpText: null,
  },
  flowVersioning: {
    name: "Flow versioning",
    helpText: null,
  },
  advancedTroubleshooting: {
    name: "Advanced troubleshooting",

    helpText: null,
  },
  mlFlowOptimization: {
    name: "ML-based flow optimization",

    helpText: null,
  },
  multiLanguageFlows: {
    name: "Multi-language flows",
    helpText: null,
  },
  scriptsRoutines: {
    name: "Scripts & routines",
    helpText: "Set up automated scripts that run behind the scenes.",
  },
  flowBuildingUpkeep: {
    name: "Flow building & upkeep",

    helpText: null,
  },
  interactiveVideoSupport: {
    name: "Interactive video chat",
    helpText: null,
  },
  photoDrivenLiveChat: {
    name: "Photo-driven live chat",
    helpText: null,
  },
  cannedResponses: {
    name: "Canned responses",
    helpText: null,
  },
  customAgentHandover: {
    name: "Custom agent handover/escalation",
    helpText: null,
  },
  websiteWidget: {
    name: "Website widget",
    helpText: null,
  },
  directVideoChatLink: {
    name: "Direct video/live chat link",
    helpText: null,
  },
  app: { name: "App", helpText: null },
  emailSupport: {
    name: "Email support",
    helpText: null,
  },
  messenger: {
    name: "Messenger",
    helpText: null,
  },
  qrCode: {
    name: "QR code",
    helpText: "Put your AI self-service to help physically on your products.",
  },
  customChannelIntegrations: {
    name: "Custom channel integrations",

    helpText: null,
  },
  customizableWidget: {
    name: "Customizable widget",
    helpText: null,
  },
  teamManagement: {
    name: "Team and admin management",
    helpText: null,
  },
  dataExports: {
    name: "Data exports",
    helpText: null,
  },
  insightDashboard: {
    name: "Unlimited Data insights dashboard",
    helpText: null,
  },
  apiAccess: {
    name: "API access",
    helpText: null,
  },
  customCRMIntegration: {
    name: "CRM or any other API integrations (incl. Zapier)",
    helpText: null,
  },
  customBranding: {
    name: "Advanced custom branding",
    helpText: null,
  },
  sso: {
    name: "Single sign-on & SAML",
    helpText: null,
  },
  helpCenter: {
    name: "Help center",
    helpText: null,
  },
  expertSupport: {
    name: "Expert support",
    helpText: null,
  },
  apiIntegrationSupport: {
    name: "API integration support",
    helpText: null,
  },
  premiumSupport: {
    name: "Premium support",
    helpText: null,
  },
  dedicatedSolutionManager: {
    name: "Dedicated onboarding manager",
    helpText: null,
  },
  translation: {
    name: "Unlimited multilanguage translation",
    helpText:
      "We're proud to support hundreds of languages and offer both machine and human translation services.",
  },
  contentGeneration: {
    name: "AI content generation and improvements",
    helpText: "Our AI improves and adds to your existing content overtime.",
  },
  eCommerce: {
    name: "eCommerce",
    helpText:
      "Help your customers choose and buy your products by integrating to e.g. Shopify or wooCommerce.",
  },
  copilot: {
    name: "Mavenoid Copilot",
    helpText:
      "Let your agents use and share your AI knowledge anywhere on the web.",
  },
  socialMedia: {
    name: "Social Media",
    helpText:
      "Add Mavenoid to Facebook Messenger, Instagram DMs, Whatsapp etc.",
  },
  imports: {
    name: "Help center and product manual imports",
    helpText: null,
  },
  solutionManagementTeam: {
    name: "Dedicated Solution Management team",
    helpText: null,
  },
}
