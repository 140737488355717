// @ts-check
import React from "react"

const HelpIcon = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg fill="none" viewBox="0 0 21 21" {...props}>
    <circle cx="10.5" cy="10.5" r="10.5" fill="#C4C4C4" />
    <path
      d="M10.4268 16.075c.6 0 .975-.45.975-.975 0-.525-.375-.975-.975-.975-.60004 0-.97504.45-.97504.975 0 .525.375.975.97504.975zm-.82504-3.225h1.50004v-.675c0-1.725 2.475-1.65 2.475-4.05 0-1.725-1.35-2.775-3.225-2.775-1.72504 0-3.00004 1.05-3.22504 2.925h1.5c.15-.9.75-1.575 1.72504-1.575 1.05 0 1.65.6 1.65 1.425 0 1.8-2.40004 1.5-2.40004 4.05v.675z"
      fill="#F0F0F0"
    />
  </svg>
)

export default HelpIcon
