// @ts-check
import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import { CardContentWrapper, CardTitle } from "../components/card"
import CardPricing from "../components/cards/cardPricing"
import { pricingContent, pricingPlans } from "../content/pricingContent"
import CardSeparator from "../components/cardSeparator"
import CardPartners from "../components/cards/cardPartners"
import colors from "../styles/colors"
import { PlanComparer } from "../components/planComparer"
import { MobileMediaQuery } from "../styles/constants"
import { useIsMobile } from "../hooks/useIsMobile"
import CardWhichPlan from "../components/cards/cardWhichPlan"
import GirishAgarwal from "../components/cards/testimonials/GirishAgarwal"
import { trackEvent } from "../utils/trackEvent"
import SEO from "../components/seo"

const LayoutWrapper = styled(Layout)``

const Wrapper = styled(CardContentWrapper)`
  ${MobileMediaQuery} {
    padding-left: 0;
    padding-right: 0;
  }
`
const SectionTitle = styled(CardTitle)`
  padding: 0 1.5rem;
  max-width: 800px;
`

const SeparatorWrapper = styled.div`
  margin-top: ${p => (p.top ? "5rem" : 0)};
  margin-bottom: ${p => (p.bottom ? "1rem" : "40px")};
  width: 100%;
  ${MobileMediaQuery} {
    margin: 3rem auto;
  }
`

const Separator = ({ top = false, bottom = false, variant }) => (
  <SeparatorWrapper top={top} bottom={bottom}>
    <CardSeparator variant={variant} />
  </SeparatorWrapper>
)

const PricingWrapper = styled.div`
  display: flex;
  margin-top: 60px;

  @media (max-width: 1070px) {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
`

const Pricing = ({ ...props }) => {
  const isMobile = useIsMobile()

  return (
    <LayoutWrapper bg="lightgrey" newsletter={null} activeTab="pricing">
      <SEO title="Pricing" />
      <Wrapper>
        <SectionTitle variant="h1">
          Find the right plan for your organization
        </SectionTitle>
        <PricingWrapper>
          {pricingContent.map(content => (
            <CardPricing
              variant={content.variant}
              key={content.title}
              title={content.title}
              subtitle={content.subtitle}
              price={content.price}
              buttonText={content.buttonText}
              buttonCaption={content.buttonCaption}
              buttonAction={content.buttonAction}
              productIncludes={content.productIncludes}
              productPitch={content.productPitch}
            ></CardPricing>
          ))}
        </PricingWrapper>
        <Separator top variant="grey" />
        <GirishAgarwal
          variant="lightgrey"
          trackCustomerStory={() => {
            trackEvent({
              category: "view_item",
              label: "pricing husqvarna customer story opened",
              siteSection: "pricing_husqvarna_testimonial",
            })
          }}
        />
        <Separator bottom variant="grey" />
        {!isMobile && (
          <CardPartners
            variant="lightgrey"
            style={{ width: "100%", color: colors.darkgrey }}
            hasCaption={false}
          />
        )}
        <SectionTitle variant="h1">Compare the plans</SectionTitle>
        <PlanComparer
          plans={pricingPlans}
          levels={pricingPlans.levels}
          ctaButtons={pricingPlans.ctaButtons}
        />
      </Wrapper>
      <CardWhichPlan style={{ marginTop: "4rem", width: "100%" }} />
    </LayoutWrapper>
  )
}

export default Pricing
